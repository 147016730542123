<template>
<div v-if="!isLandingPage && isTemplate" class="d-flex justify-content-end">
    <button
        type="button"
        class="btn btn-default btn-action mx-4 start-campaign-button d-flex justify-content-center align-items-center"
        @click="$emit('chooseTemplate', template)"
    >
        {{ $t('chooseTemplate') }}
    </button>
    <button
        type="button"
        class="btn btn-default btn-action start-campaign-button d-flex justify-content-center align-items-center"
        @click="$emit('close')"
    >
        {{ $t('closeTemplate') }}
    </button>
</div>
<hr v-if="!isLandingPage && isTemplate">
<div class="template" :class="isLandingPage ? 'landing-page': 'card-template'">
    <div class="content" :style="`background-color:${template.background}`">
        <div class="col-12 px-0 pb-1" :style="`text-align:${template.image_header_align}`">
            <img
                v-if="template.image_header_file === null"
                src="/img/brand/default-placeholder.png"
                class="img-background"
            />
            <img
                v-else
                :src="getImageUrl(template.image_header_file)"
                class="img-background"
                ref="imgHeader"
                :onload="()=>{ getSizeImg(imgHeader)}"
            />
        </div>
        <div class="col-12 py-1" :class="{'order-first': !isLandingPage}" >
            <h1 :style="`color:${template.title_color}; text-align:${template.title_align}; font-family:${template.title_font}; font-size:${template.title_font_size}px`">{{template.title_text}}</h1>
        </div>
        <div class="col-12 py-1" >
            <h4 :style="`color:${template.subtitle_color}; text-align:${template.subtitle_align}; font-family:${template.subtitle_font}; font-size:${template.subtitle_font_size}px`">{{template.subtitle_text}}</h4>
        </div>
        <template v-for="(block, index) in template.blocks" :key="index">
            <div v-if="block.image_url" class="col-12 px-0 py-1" :style="`text-align:${block.image_align}`" >
                <img
                    v-if="block.image_url === null"
                    src="/img/brand/default-placeholder.png"
                    class="img-background"
                />
                <img
                    v-else
                    :src="getImageUrl(block.image_url)"
                    :ref="el => imgBlocks[index]= el"
                    :onload="()=>{ getSizeImg( imgBlocks[index])}"
                    class="img-background"
                />
            </div>
            <div class="col-12 py-1" >
                <p :style="`color:${block.text_color}; text-align:${block.text_align}; font-family:${block.text_font}; font-size:${block.text_font_size}px`">{{block.text_text}}</p>
            </div>
            <div  v-if="block.link_url && block.link_text" class="col-12 pt-1 pb-3" :style="`text-align:${block.link_align}`" >
                <base-button
                    borderRadiusLeft
                    borderRadiusRight
                    class="p-0 start-campaign-button border-0 mw-100 "
                    size="sm"
                    :style="`color:${block.link_color}; background-color:${block.link_background}; font-family:${block.link_font}; font-size:${block.link_font_size}px`"
                >
                {{block.link_text}}
                </base-button>
            </div>
        </template>
        <footer v-if="template.footer_text" class="col-12 footer py-1 mt-auto" :style="`background-color:${template.footer_background};text-align:${template.footer_align}; font-size:${template.footer_font_size}px`">
            <div class="footer_html" :style="`color:${template.footer_color}!important; font-family:${template.footer_font}; font-size:${template.footer_font_size}px`" v-html="template.footer_text"></div>
        </footer>
    </div>
</div>
</template>
<script>
import {getImageUrl} from '@/util/helperFunctions.js'
import { onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';

export default {
  name: "TemplatePreview",
  props: ["template", "isTemplate"],
  components: {
  },
  setup(props) {
    const route = useRoute()

    const imgHeader = ref(null)
    const imgBlocks = ref([])
    const maxWidth = ref(null)
    const isLandingPage = ref(false)

    const getSizeImg = (img)=>{
        if(img && img.naturalWidth){
            let imgWidth = (img.naturalWidth*100)/600;
            imgWidth = imgWidth > 100 || maxWidth.value === null ? 100 : imgWidth;
            img.style = `width:${imgWidth}%`
        }
    }

    onMounted(()=>{
        if(route.name === 'EditMail' || route.name === 'NewMail'){
            maxWidth.value = 600;
        }
        if(route.name === 'CustomLanding' || route.name === 'LandingPreview' ){
            isLandingPage.value = true;
        }

    })

    return{
        getImageUrl,
        getSizeImg,
        imgHeader,
        imgBlocks,
        isLandingPage
    }
  }

}
</script>
<style lang="scss" scoped>
    .btn-action{
        border-radius: 100px;
    }
    .template{
        width: 100%;
        height: 100%;
        overflow-wrap: break-word;
        &.card-template{
            background-color: #FFFFFF;
            border-radius: 20px;
            padding: 16px;
        }
        &.landing-page .content{
            min-height: 100vh;
            display: flex;
            flex-direction: column;
        }

        .img-background{
            height: 100%;
            max-width: 100%;
            object-fit: cover;
        }
        .footer{
            max-width: 100%;
            .footer_html{

                word-break: break-all;

                :deep()li{
                    text-align: start!important;
                }
                :deep()p{
                    font-size: inherit !important;
                }
            }
        }

        .start-campaign-button{
            min-width: fit-content;
            overflow: hidden;
        }
    }
    :deep().ql-size-small{
        font-size: 0.75em!important;
    }
    :deep().ql-size-large{
        font-size: 1.5em!important;
    }
    :deep().ql-size-huge{
        font-size: 2.5em!important;
    }
</style>
