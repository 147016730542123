import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import { computed } from "vue";
import { useToast } from "vue-toastification";
import { useI18n } from "vue-i18n";


const useMails = () =>{

    const store = useStore();
    const route = useRoute()
    const toast  = useToast();
    const { t } = useI18n()

    const getMails = async(page, filters)=>{

        const response = await store.dispatch('mails/getMails', {page:page, filters:filters})

        return response
    }

    const getCategories = async() => {

      const response = await store.dispatch('mails/getCategories')

      return response
    }
    const getAllMails = async()=>{

        const response = await store.dispatch('mails/getAllMails')

        return response
    }
    const getMail = async(id)=>{

        const response = await store.dispatch('mails/getMail',id)

        return response
    }

    const cloneMail = async(mailId) =>{
        const response = await store.dispatch('mails/cloneMail', mailId)
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const updateMail = async(mail, mailId) =>{
        mail.append('_method', 'PUT')
        const response = await store.dispatch('mails/updateMail', {formData: mail, id: mailId})
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const storeMail = async(mail) =>{
        const response = await store.dispatch('mails/storeMail', mail)
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const deleteMail = async(mailId) =>{
        const response = await store.dispatch('mails/deleteMail', mailId)
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const getPreview = async(mail) =>{
        const response = await store.dispatch('mails/getPreviewMail', mail);
        return response
    }
    const archiveMail = async(mailId) =>{
        const response = await store.dispatch('mails/archiveMail', mailId)
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }

    const getContacts = async(page, filter)=>{

        const response = await store.dispatch('mails/getContacts', {page:page, filter:filter})
        return response
    }
    const getAllContacts = async()=>{

        const response = await store.dispatch('mails/getAllContacts')
        return response
    }
    const storeContact = async(contact) =>{
        const response = await store.dispatch('mails/storeContact', contact)
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const updateContact = async(contact, contactId) =>{
        const response = await store.dispatch('mails/updateContact', {form: contact, id: contactId})
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const deleteContact = async(contactId) =>{
        const response = await store.dispatch('mails/deleteContact', contactId)
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const importContacts = async(contacts) =>{
        const response = await store.dispatch('mails/importContacts', contacts)
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const sendMail = async(data) =>{
        const response = await store.dispatch('mails/sendMail', data)
        if(response && response.status === 200){
            toast.success(t("notification.success.general"));
        }
        return response
    }
    const getContactsSent = async(id, page) =>{
        const response = await store.dispatch('mails/getContactsSent',{ id , page})
        return response
    }
  const getMailsTemplates = async(page, filter)=>{

    const response = await store.dispatch('mails/getMailsTemplates', {page:page, filter:filter})

    return response
  }
  const cloneMailTemplate = async(mailId) =>{
    const response = await store.dispatch('mails/cloneMailTemplate', mailId)
    if(response && response.status === 200){
      toast.success(t("notification.success.general"));
    }
    return response
  }

    return{

        mailsData: computed(()=> store.state.mails.mailsData),
      mailsTemplatesData: computed(()=> store.state.mails.mailsTemplatesData),
        getMails,
        getAllMails,
        cloneMail,
        updateMail,
        storeMail,
        deleteMail,
        getPreview,
        archiveMail,
        getMail,
        getContactsSent,

        contactsData: computed(()=> store.state.mails.contactsData),
        allMails: computed(()=> store.state.mails.allMails),
        allContacts: computed(()=> store.state.mails.allContacts),
        getContacts,
        storeContact,
        updateContact,
        deleteContact,
        importContacts,
        getAllContacts,
        sendMail,
        getMailsTemplates,
        cloneMailTemplate,
        getCategories,
        categories: computed(() => store.state.mails.categories)
    }
}
export default useMails;
