<template>
      <div class="row align-items-center">
        <div class="col-12">
          <h3 class="mb-2">{{ $t("mailList") }}</h3>
        </div>
        <div class="col-12 col-sm-6 col-md-8 text-left pb-2 pb-sm-0 mb-2">
          <base-button
            borderRadiusLeft
            borderRadiusRight
            class="button-height-33 py-0 px-3"
            @click="$router.push({ name: 'NewMail' });"
          >
            <img class="mr-1" src="/img/icons/stevie/add-shape.svg" alt="Add icon">
            {{ $t("newMailTemplate") }}
          </base-button>
        </div>
        <div class="col-12 col-sm-6 col-md-4 d-flex justify-content-end pb-2 pb-sm-0">
          <div class="px-1">
            <el-select popper-class="select-no-scroll" class="select-danger select-no-scroll" :placeholder="$t('filterBy')" v-model="categorySelected" @change="listMails(1)">
              <el-option :value="null" :label="$t('templateCategories.all')" >
              </el-option>
              <el-option  v-for="category in categories" :key="category.id" :value="category.id"
                          :label="$t(`templateCategories.${category.id}`)">
              </el-option>
            </el-select>
          </div>
          <div class="px-1">
            <input
              name="search"
              v-model="search"
              @change="listMails(1)"
              :placeholder="$t('search')"
              type="text"
              maxLength="255"
              class="input-stevie"
            >
          </div>
        </div>
      </div>

    <el-table
      class="table-responsive table-flush border-top-0 min-table"
      :data="mailsData.data"
      @cell-click="cellClick"
      row-class-name="cursor-pointer"
    >
      <el-table-column prop="image" width="100px" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div v-if="row.template.image_header.url"  class="d-flex justify-content-end">
            <img alt="" :src="row.template.image_header.url" class="avatar object-fit-cover"/>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('name')" prop="name" min-width="150px" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
          <div v-if="row.name" class="media align-items-center">
            <div class="media-body w-100">
              <span class="font-weight-500 name mb-0 text-sm text-dark">{{
                    row.name
                }}</span>
            </div>
          </div>
        </template>
      </el-table-column>

      <el-table-column :label="$t('status')" prop="title" min-width="80px" label-class-name="border-top-0 text-prussian-blue">
        <template v-slot="{ row }">
            <span  class="status font-weight-500 text-dark text-capitalize">{{ $t(`statusList.${status[row.status].name}`) }}</span>
        </template>
      </el-table-column>

      <el-table-column :label="$t('actions')" min-width="130px" label-class-name="border-top-0 text-prussian-blue d-flex justify-content-end" align="right">
        <template v-slot="{ row }">
          <el-tooltip placement="top" :content="$t('clone')">
            <base-button class="btn-default bg-transparent border-0 shadow-none px-0" @click="cloneItem(row.id)">
              <em class="fas fa-clone text-prussian-blue"></em>
            </base-button>
          </el-tooltip>
          <el-tooltip placement="top" :content="$t('preview')">
            <base-button class="btn-default bg-transparent border-0 shadow-none px-0" @click="showMailTemplate(row)">
              <em class="fas fa-search text-prussian-blue"></em>
            </base-button>
          </el-tooltip>
          <el-tooltip placement="top" :content="$t('delete')">
            <base-button :disabled="row.status === 4" class="btn-default bg-transparent border-0 shadow-none px-0" @click="modalDeleteMail(row.id)">
              <em class="fas fa-trash text-prussian-blue"></em>
            </base-button>
          </el-tooltip>
          <el-tooltip placement="top" :content="$t('showSent')">
            <base-button :disabled="row.status === 4" class="btn-default bg-transparent border-0 shadow-none px-0" @click="showSent(row.id)">
              <em class="fas fa-mail-bulk text-prussian-blue"></em>
            </base-button>
          </el-tooltip>
        </template>
      </el-table-column>

    </el-table>

    <div class="card-footer py-4 d-flex justify-content-end">
      <base-pagination :modelValue="mailsData.current_page" @update:modelValue="changePage" :total="mailsData.total" :perPage="mailsData.per_page"></base-pagination>
    </div>
  <!-- </card> -->
  <ConfirmModal
    v-if="showConfirmModal"
    :showModal="showConfirmModal"
    :modalInfo="modalConfirm"
    :isLoading ="isLoading"
    @confirm="modalConfirm.confirmAction()"
    @cancel="modalConfirm.cancelAction(null)"
    @closeModal="showConfirmModal=false"
  ></ConfirmModal>
  <modal  v-model:show="showPreview" :showClose="true" :is-allowed-close-modal="true" @close="showPreview= false" header-classes="pb-1" body-classes="pt-1">
    <template v-slot:header>
    </template>
    <TemplatePreview v-if="templatePreview" :template="templatePreview" :isTemplate="false"></TemplatePreview>
  </modal>
</template>

<script>
import {
  ElTable,
  ElTableColumn,
  ElDropdownMenu,
  ElDropdownItem,
  ElDropdown,
  ElSelect,
  ElOption
} from "element-plus";
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import useMails from "@/composables/useMails";
import ConfirmModal from "@/views/Components/ConfirmModal";
import { mailTemplateStatus } from '@/util/Settings';
import modal from '@/components/Modal';
import TemplatePreview from '@/views/Components/TemplatePreview.vue';
import { TemplateFormClass } from '@/util/Models/TemplateClass.js';

export default {
  name: "MailsList",
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
    [ElDropdown.name]: ElDropdown,
    [ElDropdownItem.name]: ElDropdownItem,
    [ElDropdownMenu.name]: ElDropdownMenu,
    ElSelect,
    ElOption,
    ConfirmModal,
    modal,
    TemplatePreview
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const showConfirmModal = ref(false)
    const templatePreview = ref(null)
    const selectedTemplate = ref(null)
    const showPreview = ref(false)

    const { mailsData, getMails, cloneMail, deleteMail, getCategories, categories }= useMails();
    const mailIdToManage = ref(null)
    const modalConfirm = ref({
      title: null,
      subtitle: null,
      textCancel: null,
      textConfirm: null,
      confirmAction: null,
      cancelAction: null,
      allowClose:false,
    });
    const isLoading = ref(false)
    const status = ref(mailTemplateStatus);
    const categorySelected = ref(null);
    const search = ref(null);

    const changePage = (event)=>{
      if(event !== mailsData.value.current_page){
        listMails(event)
      }
    }

    const listMails = async (page = 1) => {
      let filters = {}
      if (categorySelected.value) {
        filters.category = categorySelected.value
      }
      if (search.value) {
        filters.search = search.value
      }
      await getMails(page, Object.keys(filters).length === 0 ? null : filters);
    }

    const listCategories = async () => {
      await getCategories()
    }

    const editMail = (id, saveFilter = true) => {
      if(saveFilter){
        localStorage.setItem('mailsFilters', JSON.stringify({page: mailsData.value.current_page}))
      }
      router.push({ name: 'EditMail', params: { id: id } });
    }
    const cloneItem = async (id) => {
      const response = await cloneMail(id)
      if (response && response.status === 200) {
        editMail(response.data.id, false)
      }
    }
    const showSent = async (id, saveFilter = true) => {
      if(saveFilter){
        localStorage.setItem('mailsFilters', JSON.stringify({page: mailsData.value.current_page}))
      }
      router.push({ name: 'EmailsSent', params: { id: id } });
    }
    const modalDeleteMail = async (mailId) => {
      if(mailId){
        modalConfirm.value = {
          allowClose: false,
          title:'delete',
          subtitle: 'modalDeleteMail.subtitle',
          textCancel: 'no',
          textConfirm: 'yes',
          confirmAction: initDeleteMail,
          cancelAction: modalDeleteMail,
        }
        mailIdToManage.value = mailId;
        showConfirmModal.value = true;

      } else {
        modalConfirm.value = {};
        mailIdToManage.value = null;
        showConfirmModal.value = false;
      }
    }

    const initDeleteMail = async () => {
      isLoading.value= true;
      const response = await deleteMail(mailIdToManage.value)
      if(response && response.status === 200){
        listMails(mailsData.value.current_page)
      }
      isLoading.value= false;
      modalDeleteMail(null)

    }

    const cellClick = ({id: mailId}, { property: columnName }) => {
      columnName && editMail(mailId);
    }

    const showMailTemplate = (template) => {
      if(template){
        showPreview.value = true
        templatePreview.value = new TemplateFormClass(template)
      }
      selectedTemplate.value = template ? template : null;
    }

    onMounted(()=>{
      const { page } = route.query;
      mailsData.value.current_page = page ? page : 1;
      if(page){
        router.replace({...router.currentRoute, query: {}})
      }
        listMails(mailsData.value.current_page)
        listCategories()
    })

    return {
      mailsData,
      showConfirmModal,
      modalConfirm,
      isLoading,
      status,
      editMail,
      modalDeleteMail,
      cloneItem,
      initDeleteMail,
      listMails,
      changePage,
      showSent,
      selectedTemplate,
      showPreview,
      templatePreview,
      showMailTemplate,
      categorySelected,
      categories,
      search,
      cellClick,
    };
  },
}
</script>

<style scoped lang="scss">
.el-table_1_column_4 th {
  text-align: right;
}
:deep() .el-table__body-wrapper{
  min-height: 350px!important;
}
.min-table{
  min-height: 450px;
  max-width: 99%!important;
}
.dropdown-item{
  height: 33px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  &:hover{
    color: #16181b!important;
    background-color: #f6f9fc!important;
  }
}

</style>
